import $ from '@vaersaagod/tools/Dom';
import request from '@vaersaagod/tools/request';
import serialize from 'form-serialize';

export default el => {
    const form = $(el);
    const field = form.find('input[type="email"]');
    const disclaimer = form.find('input[type="checkbox"]');
    const redirect = form.data('redirect');

    const onSubmit = e => {
        e.preventDefault();
        if (!disclaimer.get(0).checked) {
            disclaimer.parent().addClass('text-error');
            return;
        }
        const node = e.triggerTarget;
        const params = serialize(node, { disabled: true, empty: true, hash: false });
        request
            .post('/')
            .accept('application/json')
            .send(params)
            .then(res => {
                const text = JSON.parse(res.text);
                if (text.success) {
                    if (redirect) {
                        window.location = redirect;
                    } else {
                        form.parent().addClass('is-success');
                        form.removeClass('is-error').addClass('is-success');
                        el.reset();
                        destroy();
                    }
                } else {
                    form.addClass('is-error');
                }
            }, error => {
                console.error('Error', error);
                form.addClass('is-error');
            });
    };

    const onCheck = () => {
        disclaimer.parent().removeClass('text-error');
    };

    const hideError = () => {
        form.removeClass('is-error');
    };

    const init = () => {
        form.on('submit', onSubmit);
        field.on('focus', hideError);
        disclaimer.on('change', onCheck);
    };

    const destroy = () => {
        form.off('submit', onSubmit);
        field.off('focus', hideError);
        disclaimer.off('change', onCheck);
    };

    return {
        init,
        destroy
    };
};
