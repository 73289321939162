import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

const getScrollPaddingTop = () => parseInt($('body').css('scrollPaddingTop'), 10);

const clickAndScroll = e => {
    const href = e.triggerTarget.getAttribute('href');
    if (href && href.indexOf('#') !== -1) {
        const target = $(href);
        if (target.length) {
            e.preventDefault();
            const offset = target.offset().top;
            gsap.to(window, { duration: 1.25, scrollTo: { y: offset, autoKill: false, offsetY: getScrollPaddingTop() }, ease: 'cubic.inOut' });
        }
    }
};

export default clickAndScroll;
