import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';
import Flickity from 'flickity-fullscreen';
import Headroom from 'headroom.js';
import Viewport from '@vaersaagod/tools/Viewport';
import Dispatch from '@vaersaagod/tools/Dispatch';
import * as Events from '../lib/events';

const MOBILE_CUTOFF = 1024;

export default el => {
    const links = $(el).find('a');
    const wrapper = $(el).find('[data-wrapper]').get(0);
    const targets = $('body').find('[data-anchor-item]').get();

    let animating = false;
    let activeTargetId = '';
    let headroom = null;
    let flkty = null;

    const getScrollPaddingTop = () => parseInt($('body').css('scrollPaddingTop'), 10);

    const selectFlickity = target => {
        if (flkty) {
            const index = parseInt(target.data('item'), 10);
            flkty.select(index, false, false);
        }
    };

    const onClick = e => {
        const trigger = $(e.triggerTarget);
        const target = $(trigger.attr('href'));
        if (target) {
            e.preventDefault();
            links.attr('aria-current', null);
            trigger.attr('aria-current', true);
            animating = true;
            selectFlickity(trigger);
            gsap.to(window, {
                duration: 1.25,
                scrollTo: { y: target.position().top, autoKill: false, offsetY: getScrollPaddingTop() },
                ease: 'cubic.inOut',
                onComplete: () => {
                    animating = false;
                }
            });
        }
    };

    const onScroll = () => {
        if (!animating) {
            const scrollTop = window.scrollY;
            const active = targets.filter(node => {
                const rect = node.getBoundingClientRect();
                return scrollTop + rect.y <= (scrollTop + window.innerHeight / 6);
            }).pop() || {};

            const { id } = active;

            if (id) {
                if (id !== activeTargetId) {
                    activeTargetId = id;
                    const target = links.filter(`[href="#${id}"]`);
                    links.attr('aria-current', null);
                    target.attr('aria-current', true);
                    selectFlickity(target);
                }
            }
        }
    };

    const onResize = () => {
        if (flkty && Viewport.width >= MOBILE_CUTOFF) {
            flkty.destroy();
            flkty = null;
        } else if (!flkty && Viewport.width < MOBILE_CUTOFF) {
            flkty = new Flickity(wrapper, {
                contain: true,
                cellAlign: 'left',
                prevNextButtons: false,
                pageDots: false,
                freeScroll: true,
                freeScrollFriction: 0.045
            });
            flkty.resize();

            flkty.on('dragStart', () => {
                document.ontouchmove = e => e.preventDefault();
            });

            flkty.on('dragEnd', () => {
                document.ontouchmove = () => true;
            });
        }
    };

    const onMenuOpen = () => {
        headroom.freeze();
    };

    const onMenuClose = () => {
        headroom.unfreeze();
    };

    const init = () => {
        headroom = new Headroom(el);
        headroom.init();
        links.on('click', onClick);
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        Dispatch.on(Events.MENU_OPEN, onMenuOpen);
        Dispatch.on(Events.MENU_CLOSE, onMenuClose);
    };

    const destroy = () => {
        headroom.destroy();
        links.off('click', onClick);
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        Dispatch.off(Events.MENU_OPEN, onMenuOpen);
        Dispatch.off(Events.MENU_CLOSE, onMenuClose);
        if (flkty) {
            flkty.destroy();
        }
    };

    return {
        init,
        destroy
    };
};
