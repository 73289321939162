import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

export default el => {
    const dom = $(el);
    const next = dom.find('[data-next]');
    const prev = dom.find('[data-prev]');
    const scrollers = dom.find('[data-scroll]');
    const dates = dom.find('[data-date]');
    const months = dom.find('[data-month]');
    const monthsNodes = months.get();
    const rows = dom.find('[data-month-row]');
    const firstOffset = 270;

    let currentMonthIndex = 0;
    let animating = false;

    const updateWidget = () => {
        console.log('updateWidget');
        rows.attr('hidden', '');
        rows.eq(currentMonthIndex).attr('hidden', null);
        rows.eq(currentMonthIndex + 1).attr('hidden', null);
        next.attr('disabled', currentMonthIndex > monthsNodes.length - 2 ? '' : null);
        prev.attr('disabled', currentMonthIndex < 1 ? '' : null);
    };

    const getScrollPaddingTop = () => parseInt($('body').css('scrollPaddingTop'), 10);

    const scrollToTarget = target => {
        animating = true;
        gsap.to(window, {
            duration: 1.25,
            scrollTo: { y: target.position().top, autoKill: false, offsetY: getScrollPaddingTop() },
            ease: 'cubic.inOut',
            onComplete: () => {
                animating = false;
                target.get(0).focus();
                console.log(target.get(0));
                updateWidget();
            }
        });
    };

    const onClickMonth = e => {
        const trigger = $(e.triggerTarget);
        const target = $(trigger.attr('href'));
        if (target) {
            e.preventDefault();
            currentMonthIndex = months.nodes.indexOf(target.get(0));
            scrollToTarget(target);
        }
    };

    const onClickDate = e => {
        const trigger = $(e.triggerTarget);
        const target = $(trigger.attr('href'));
        if (target) {
            e.preventDefault();
            currentMonthIndex = rows.nodes.indexOf(trigger.parent('[data-month-row]').get(0));
            scrollToTarget(target);
        }
    };

    const onPaginate = e => {
        const button = e.triggerTarget;
        let shouldUpdate = false;
        if (button === next.get(0) && currentMonthIndex < monthsNodes.length - 1) {
            currentMonthIndex++;
            shouldUpdate = true;
        } else if (button === prev.get(0) && currentMonthIndex > 0) {
            currentMonthIndex--;
            shouldUpdate = true;
        }
        if (shouldUpdate) {
            scrollToTarget(months.eq(currentMonthIndex));
        }
    };

    const onScroll = () => {
        if (!animating) {
            const scrollTop = window.scrollY;
            const active = monthsNodes.filter(node => {
                const rect = node.getBoundingClientRect();
                return scrollTop + rect.y <= scrollTop + firstOffset;
            }).pop() || {};

            if (active) {
                const activeIndex = months.nodes.indexOf(active);
                if (activeIndex !== currentMonthIndex) {
                    currentMonthIndex = activeIndex;
                    updateWidget();
                }
            }
        }
    };

    const init = () => {
        next.on('click', onPaginate);
        prev.on('click', onPaginate);
        scrollers.on('click', onClickMonth);
        dates.on('click', onClickDate);
        Viewport.on('scroll', onScroll);
    };

    const destroy = () => {
        next.off('click', onPaginate);
        prev.off('click', onPaginate);
        scrollers.off('click', onClickMonth);
        dates.off('click', onClickDate);
        Viewport.off('scroll', onScroll);
    };

    return {
        init,
        destroy
    };
};
