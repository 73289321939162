import $ from '@vaersaagod/tools/Dom';

export default el => {
    const dom = $(el);
    const toggles = dom.find('[aria-expanded]');

    const toggle = e => {
        const button = $(e.triggerTarget);
        const status = button.attr('aria-expanded');
        const target = $(button.get(0).parentElement.nextElementSibling);
        // toggles.attr('aria-expanded', false);
        button.attr('aria-expanded', status === 'false' ? 'true' : false);
        target.attr('hidden', status === 'false' ? null : '');
    };

    const init = () => {
        toggles.on('click', toggle);
    };

    const destroy = () => {
        toggles.off('click', toggle);
    };

    return {
        init,
        destroy
    };
};
