import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Headroom from 'headroom.js';
import gsap from 'gsap';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);
    const hamburger = dom.find('[data-hamburger]');
    const hamburgerTop = hamburger.find('i:nth-of-type(1)').get(0);
    const hamburgerMiddle = hamburger.find('i:nth-of-type(2)').get(0);
    const hamburgerBottom = hamburger.find('i:nth-of-type(3)').get(0);

    let menuOpen = false;
    let headroom = null;

    const getLineOffset = () => {
        const style = getComputedStyle(hamburgerTop);
        return hamburgerTop.offsetHeight + parseInt(style.marginBottom);
    };

    const hamburgerToX = () => {
        const offset = getLineOffset();
        gsap.to(hamburgerTop, { duration: 0.15, y: offset, ease: 'sine.in' });
        gsap.to(hamburgerBottom, { duration: 0.15, y: offset * -1, ease: 'sine.in', onComplete: () => {
            gsap.set(hamburgerMiddle, { opacity: 0 });
            gsap.to(hamburgerTop, { duration: 0.2, rotationZ: '45deg', transformOrigin: '50% 50%', ease: 'power3.out' });
            gsap.to(hamburgerBottom, { duration: 0.2, rotationZ: '-45deg', transformOrigin: '50% 50%', ease: 'power3.out' });
        } });
    };

    const xToHamburger = () => {
        gsap.to(hamburgerTop, { duration: 0.1, rotationZ: '0deg', transformOrigin: '50% 50%', ease: 'sine.in' });
        gsap.to(hamburgerBottom, {
            duration: 0.1,
            rotationZ: '0deg',
            transformOrigin: '50% 50%',
            ease: 'sine.in',
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 1 });
                gsap.to(hamburgerTop, { duration: 0.15, y: 0, ease: 'sine.in', clearProps: 'all' });
                gsap.to(hamburgerBottom, { duration: 0.15, y: 0, ease: 'sine.in', clearProps: 'all' });
            }
        });
    };

    const onMenuOpen = () => {
        menuOpen = true;
        headroom.freeze();
        hamburgerToX();
    };

    const onMenuClose = () => {
        menuOpen = false;
        headroom.unfreeze();
        xToHamburger();
    };

    const toggleMenu = e => {
        e.preventDefault();
        Dispatch.emit(menuOpen ? Events.MENU_CLOSE : Events.MENU_OPEN);
    };

    const init = () => {
        if (Headroom.cutsTheMustard) {
            headroom = new Headroom(el);
            headroom.init();
        }
        hamburger.on('click', toggleMenu);
        Dispatch.on(Events.MENU_OPEN, onMenuOpen);
        Dispatch.on(Events.MENU_CLOSE, onMenuClose);
    };

    const destroy = () => {
        if (headroom) {
            headroom.destroy();
        }
        hamburger.off('click', toggleMenu);
        Dispatch.off(Events.MENU_OPEN, onMenuOpen);
        Dispatch.off(Events.MENU_CLOSE, onMenuClose);
    };

    return {
        init,
        destroy
    };
};
