import $ from '@vaersaagod/tools/Dom';
import Flickity from 'flickity-fullscreen';
import Viewport from '@vaersaagod/tools/Viewport';

const MOBILE_CUTOFF = 1024;

export default el => {
    const dom = $(el);
    const wrapper = dom.find('[data-slides]').get(0);
    const prevnext = dom.find('[data-prevnext]');
    const prev = dom.find('[data-prev]');
    const next = dom.find('[data-next]');
    const removeSliderOnLarge = wrapper.children.length < 3;
    let flkty = null;

    const destroySlider = () => {
        prevnext.addClass('hidden');
        prev.off('click');
        next.off('click');
        if (flkty) {
            flkty.destroy();
            flkty = null;
        }
    };

    const initSlider = () => {
        flkty = new Flickity(wrapper, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
            selectedAttraction: 0.09,
            friction: 0.55
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
        });

        prev.on('click', () => {
            flkty.previous();
        });
        next.on('click', () => {
            flkty.next();
        });
        prevnext.removeClass('hidden');
    };

    const needsSlider = () => (!flkty && Viewport.width < MOBILE_CUTOFF) || (!flkty && !removeSliderOnLarge && Viewport.width >= MOBILE_CUTOFF);

    const onResize = () => {
        if (needsSlider()) {
            initSlider();
        } else if (flkty && removeSliderOnLarge && Viewport.width >= MOBILE_CUTOFF) {
            destroySlider();
        }
    };

    const init = () => {
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
        destroySlider();
    };

    return {
        init,
        destroy
    };
};
