import $ from '@vaersaagod/tools/Dom';

import clickAndScroll from '../lib/clickAndScroll';

export default el => {
    const dom = $(el);
    const children = dom.find('a');

    const init = () => {
        children.on('click', clickAndScroll);
        dom.on('click', clickAndScroll);
    };

    const destroy = () => {
        children.off('click', clickAndScroll);
        dom.off('click', clickAndScroll);
    };

    return {
        init,
        destroy
    };
};
