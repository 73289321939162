import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

export default el => {
    const dom = $(el);
    const list = dom.find('[data-list]').get(0);
    const links = dom.find('[data-image]');

    const getImage = target => {
        const id = target.data('image');
        return dom.find(id).get(0);
    };

    const onMouseEnter = e => {
        const target = $(e.target);
        const image = getImage(target);

        image.removeAttribute('hidden');

        const imageHeight = image.clientHeight;
        const bottomOfList = list.clientHeight;
        let offset = target.nodes[0].offsetTop - list.offsetTop;
        if (offset + imageHeight > bottomOfList) {
            offset = bottomOfList - imageHeight;
        }

        gsap.set(image, {
            top: offset,
            autoAlpha: 0
        });

        gsap.to(image, {
            autoAlpha: 1,
            duration: 0.4,
            ease: 'power1.inOut'
        });
    };

    const onMouseLeave = e => {
        const target = $(e.target);
        const image = getImage(target);

        gsap.to(image, {
            autoAlpha: 0,
            duration: 0.25,
            ease: 'power1.inOut'
        });
    };

    const init = () => {
        links.on('mouseenter', onMouseEnter);
        links.on('mouseleave', onMouseLeave);
    };

    const destroy = () => {
        links.off('mouseenter', onMouseEnter);
        links.off('mouseleave', onMouseLeave);
    };

    return {
        init,
        destroy
    };
};
