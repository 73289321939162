import $ from '@vaersaagod/tools/Dom';

export default el => {
    const dom = $(el);
    const field = dom.find('[type="file"]');
    const output = dom.find('[data-output]');
    const button = dom.find('button');

    const change = e => {
        const files = [...e.target.files];
        console.log('change');
        if (files.length) {
            output.html(files.map(file => file.name).join('<br>'));
        } else {
            output.html('Ingen filer valgt');
        }
    };

    const click = () => {
        field.get(0).click();
    };

    const init = () => {
        field.on('change', change);
        button.on('click', click);
    };

    const destroy = () => {
        field.off('change', change);
        button.off('click', click);
    };

    return {
        init,
        destroy
    };
};
