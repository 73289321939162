import $ from '@vaersaagod/tools/Dom';

import gsap from 'gsap';

export default (el, props) => {
    const poster = $(el).find('[data-video-poster]').get(0);
    const embed = $(el).find('[data-video-embed]').get(0);

    const { embedCode } = props;

    let hasClicked = false;

    const onThumbClick = e => {
        e.preventDefault();
        if (hasClicked) {
            return;
        }
        hasClicked = true;
        $(embed).html(embedCode);
        requestAnimationFrame(() => {
            gsap.timeline({
                onComplete() {
                    poster.hidden = true;
                }
            })
                .to(poster, { duration: 0.65, opacity: 0, ease: 'sine.in' });
        });
    };

    $(poster).on('click', onThumbClick);

    return {
        destroy() {
            $(poster).off('click');
        }
    };
};
