import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

export default el => {
    const video = el;

    const timeupdate = () => {
        if (video.currentTime < 0.001) {
            return;
        }
        video.removeEventListener('timeupdate', timeupdate);
        gsap.to(video, { duration: 0.5, opacity: 1, ease: 'power1.inOut' });
    };

    const init = () => {
        video.addEventListener('timeupdate', timeupdate);
    };

    const destroy = () => {
        video.removeEventListener('timeupdate', timeupdate);
    };

    return {
        init,
        destroy
    };
};
