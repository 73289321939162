import $ from '@vaersaagod/tools/Dom';
import Flickity from 'flickity-fullscreen';

export default el => {
    const dom = $(el);
    const wrapper = dom.find('[data-slides]').get(0);
    const prev = dom.find('[data-prev]');
    const next = dom.find('[data-next]');
    const fullscreen = dom.find('[data-fullscreen]');
    let flkty = null;

    const init = () => {
        flkty = new Flickity(wrapper, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
            fullscreen: true,
            selectedAttraction: 0.09,
            friction: 0.55
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
        });

        prev.on('click', () => {
            flkty.previous();
        });

        next.on('click', () => {
            flkty.next();
        });
        fullscreen.on('click', () => {
            flkty.viewFullscreen();
        });
    };

    const destroy = () => {
        flkty.destroy();
        prev.off('click');
        next.off('click');
        fullscreen.off('click');
    };

    return {
        init,
        destroy
    };
};
