import $ from '@vaersaagod/tools/Dom';

export default el => {
    const dom = $(el);
    const button = dom.find('[aria-expanded]');
    const form = dom.find('[data-form]');
    const field = dom.find('[data-field]');

    let isOpen = false;

    const open = e => {
        if (!isOpen) {
            isOpen = true;
            button.attr('aria-expanded', 'true');
            form.attr('hidden', null);
            field.focus();
        }
    };

    const close = e => {
        if (!dom.hasClass('is-success') && isOpen && !form.contains(e.relatedTarget)) {
            isOpen = false;
            button.attr('aria-expanded', 'false');
            form.attr('hidden', '');
        }
    };

    const init = () => {
        button.on('click', open);
        form.on('focusout', close);
    };

    const destroy = () => {
        button.off('click', open);
        form.off('focusout', close);
    };

    return {
        init,
        destroy
    };
};
